import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PAGE } from 'constants';
import { fetchEnterprises } from 'actions/business';
import AddMetaTags from '../../components/add_meta_tags';
import LeftSidebar from '../../components/left_sidebar';
import SidebarCatalogueOfProduct from '../../components/sidebar_catalogueOfProduct';
import FadeIn from 'react-fade-in';
import MedalImg from '../../assets/images/medal.png';

const AllEnterPrises = () => {
  const dispatch = useDispatch();
  const { data: enterprises } = useSelector(state => state.business.list_enterprises);
  const [page, setPage] = useState(PAGE);
  const [limit, setLimit] = useState(40);
  const [numItems, setNumItems] = useState(20);

  const fetchEnterprisesData = () => {
    dispatch(fetchEnterprises({ limit, page }));
  };
  const handleLoadmoreProduct = () => {
    setNumItems(prevNum => prevNum + 20);
  };
  // chưa handle case hơn 40 doanh nghiệp phải fetch thêm data
  useEffect(() => {
    fetchEnterprisesData();
  }, []);

  return (
    <div className="enterprises">
      <AddMetaTags title={'Danh sách doanh nghiệp'} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-6 col-sm-12 order-md-1 order-xs-2">
          <div className="cont-center">
            <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">Chợ sản xuất</p>
            <div className="mt-1">
              <h5 className="title-line-lt text-uppercase fw-bold my-3">Doanh nghiệp sản xuất</h5>
              <FadeIn className="row row-cols-3 prod-list" childClassName="col">
                {enterprises &&
                  enterprises.slice(0, numItems).map(function (enterprise) {
                    return (
                      <div key={enterprise.id} className="item-lst hover">
                        <Link to={`/doanh-nghiep/${enterprise?.slug}`} state={{ enterprise }}>
                          <div
                            className="bg-img-orgnal position-relative"
                            style={{
                              background: `url(${enterprise?.image || 'images/photos/no_image.png'}`,
                            }}
                          />
                          {!!enterprise.prestige && (
                            <div className="position-absolute enterprise-pretige">
                              <img src={MedalImg} alt="" />
                            </div>
                          )}
                        </Link>
                      </div>
                    );
                  })}
              </FadeIn>
              {numItems < enterprises.length && (
                <div className="product-more background-clr-f5 border-0 w-100" onClick={() => handleLoadmoreProduct()}>
                  <span className="txt hover fw-500">
                    <u>Xem thêm</u>
                  </span>
                  <span
                    className="icon-arrow-solid"
                    style={{ fontSize: '8px', marginLeft: '2px', color: 'grey' }}
                  ></span>
                </div>
              )}
            </div>
          </div>
        </div>
        <SidebarCatalogueOfProduct />
      </div>
    </div>
  );
};

export default AllEnterPrises;
