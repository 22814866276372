import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { fetchProductOfEnterprises } from 'actions/business';
import { useDispatch, useSelector } from 'react-redux';
import AddMetaTags from '../../components/add_meta_tags';
import Left_sidebar from '../../components/left_sidebar';
import SidebarCatalogueOfProduct from '../../components/sidebar_catalogueOfProduct';
import NextArrow from '../../assets/images/next-arr.png';
import EnterpriseInfo from './components/EnterpriseInfo';
import ProductOfEnterprise from './components/ProductOfEnterprise';

const EnterpriseDetail = () => {
  const [seeMore, setSeeMore] = useState(false);
  const { state } = useLocation();
  const enterprise = state?.enterprise;
  const dispatch = useDispatch();
  const { data: products } = useSelector(state => state.business.list_product_of_enterprise);

  const handleSeeMoreInfo = () => setSeeMore(true);

  const fetchProductOfEnterprise = () => {
    if (enterprise) {
      dispatch(fetchProductOfEnterprises({ business_association_id: enterprise?.id }));
    }
  };

  useEffect(() => {
    fetchProductOfEnterprise();
  }, []);

  return (
    <div className="enterprise-detail">
      <AddMetaTags title={enterprise?.name} />
      <div className="row">
        <Left_sidebar />
        <div className="col-md-6 col-sm-12 order-md-1 order-xs-2">
          <div className="cont-center">
            <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">Chợ sản xuất</p>
            <div className="mt-1 d-flex align-items-center">
              <Link to="/danh-sach-doanh-nghiep">
                <h5 className="text-uppercase fw-bold my-3">Doanh nghiệp sản xuất</h5>
              </Link>
              <div style={{ width: '20px', height: '20px', margin: '0px 0.35rem' }}>
                <img src={NextArrow} alt="breadcumb" />
              </div>
              <div className="">{enterprise?.name}</div>
            </div>
            <EnterpriseInfo enterprise={enterprise} seeMore={seeMore} onSeeMore={handleSeeMoreInfo} />
            {products && <ProductOfEnterprise products={products} />}
          </div>
        </div>
        <SidebarCatalogueOfProduct />
      </div>
    </div>
  );
};

export default EnterpriseDetail;
