import React, { useEffect, useState } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { useNavigate, useParams } from "react-router-dom";
import {fetchDetailRefundInvoice, requestComplaintStatus, updateStatusRefundInvoice} from "actions/refund_invoice";
import { useDispatch, useSelector } from "react-redux";
import RefundStatus from "../../context/Inovice/refundStatus";
import OrderInformation from "./Components/OrderInformation";
import InvoiceItems from "./Components/InvoiceItems";
import ShowMediaModal from "./Components/ShowMediaModal";
import { Col, Row, Card } from 'react-bootstrap';
import Swal from "sweetalert2";
import './DetailRefundInvoice.scss'
import RefundModal from "./Components/RefundModal";

const DetailRefundInvoice = ({type}) => {
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const { refundStatus } = RefundStatus();
  const { data }  = useSelector(state => state.refundInvoices.detailRefundInvoice);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [returnModal, setReturnModal] = useState(false)

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdateComplaintStatusRefundInvoice = async (status) => {
    const messages = {
      cancelled: {
        context: "Xác nhận huỷ yêu cầu khiếu nại",
        contentMessage: "Khiếu nại của bạn đã bị huỷ"
      },
      pending: {
        context: "Gửi Khiếu nại đến quản trị viên Hai Nông",
        contentMessage: "Khiếu nại của bạn đã được gửi đến Hai Nông, chúng tôi sẽ xử lý và liên hệ bạn trong 3-4 ngày làm việc!"
      }
    };

    const { context = "", contentMessage = "" } = messages[status] || {};

    Swal.fire({
      title: 'Thông báo',
      text: context,
      icon: 'success',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#009a59',
      confirmButtonText: 'Xác nhận',
      cancelButtonText: 'Huỷ'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(requestComplaintStatus({ invoiceUserId: invoiceId, complaint_status: status, type }));

        Swal.fire('Thông báo', contentMessage, 'success').then(() => {
          navigate(`/nguoi-dung/thong-tin-don-mua-hoan-tra/${invoiceId}`);
        });
      }
    });
  };

  const handleUpdateStatusRefundInvoice = async (status) => {
    if(status === 'rejected'){
      setReturnModal(true)
    } else {
      const messages = {
        cancelled: {
          context: "Xác nhận huỷ yêu cầu trả hàng",
          contentMessage: "Yêu cầu của bạn đã bị huỷ",
          navigatePath: `/nguoi-dung/thong-tin-don-hang-mua/${data.invoice_user_id}`
        },
        approved: {
          context: "Xác nhận chấp nhận yêu cầu trả hàng",
          contentMessage: "Đã chấp nhận yêu cầu trả hàng",
          navigatePath: `/nguoi-dung/thong-tin-don-ban-hoan-tra/${data.id}`
        }
      };

      const { context = "", contentMessage = "", navigatePath = "/nguoi-dung/don-hang-cua-toi"} = messages[status] || {};
      Swal.fire({
        title: 'Thông báo',
        text: context,
        icon: 'success',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#009a59',
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Huỷ'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(updateStatusRefundInvoice({ invoiceId: invoiceId, status: status }));
          navigate(navigatePath);
          setTimeout(() => {
            dispatch(fetchDetailRefundInvoice({type: type, invoiceId: invoiceId}));
          }, 1000);
        }
      });
    }
  }

  useEffect(() => {
    dispatch(fetchDetailRefundInvoice({type: type, invoiceId: invoiceId}));
  }, [invoiceId]);

  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <OrderInformation data={data} invoiceStatus={refundStatus} showReturnInfo={true} />
            <InvoiceItems sellerName={data?.business_association_name || data.seller_name} invoiceItems={data.invoice_items} totalPrice={ data.invoice_user_id !== undefined ? data.total_price : data.price_total} />
            { (data.status === 'rejected') ?
                <Card>
                  <Card.Header><p className="fw-bold">Lý do từ chối yêu cầu tử nguời bán</p></Card.Header>
                  <Card.Body>
                    <p>{ data?.reason }</p>
                    <Row>
                      {Array.isArray(data.seller_images) && data.seller_images.length > 0 ? (
                        data.seller_images.slice(0, 3).map((media, index) => (
                          <Col key={index} xs={3} onClick={handleOpenModal}>
                            {index === 2 && data.seller_images.length > 3 ? (
                              <div className="image-overlay">
                                <img
                                  src={media.name}
                                  alt="img"
                                  title="photo"
                                  className="img-cover"
                                />
                                <div className="overlay">+{data.seller_images.length - 3}</div>
                              </div>
                            ) : (
                              <img
                                src={media.name}
                                alt="img"
                                title="photo"
                                className="img-cover"
                              />
                            )}
                          </Col>
                        ))
                      ) : (
                        ''
                      )}
                      {Array.isArray(data.seller_images) && data.seller_images.some(media => media.name.includes("mp4")) && (
                        <Col xs={3} onClick={handleOpenModal}>
                          {data.seller_images
                            .filter(media => media.name.includes("mp4"))
                            .map((media, index) => (
                              <video key={index} controls className="video-cover">
                                <source src={media.name} type="video/mp4" />
                              </video>
                            ))}
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                  <ShowMediaModal title='Hình ảnh từ người bán' show={showModal} handleClose={handleCloseModal} mediaList={data.seller_images || []} />
                </Card>
              :
              (data.status === 'requested') ?
                <Card>
                  <Card.Header><p className="fw-bold">Lý do trả hàng từ người mua</p></Card.Header>
                  <Card.Body>
                    <p>{ data?.request }</p>
                    <Row>
                      {Array.isArray(data.buyer_images) && data.buyer_images.length > 0 ? (
                        data.buyer_images.slice(0, 3).map((media, index) => (
                          <Col key={index} xs={3} onClick={handleOpenModal}>
                            {index === 2 && data.buyer_images.length > 3 ? (
                              <div className="image-overlay">
                                <img
                                  src={media.name}
                                  alt="img"
                                  title="photo"
                                  className="img-cover"
                                />
                                <div className="overlay">+{data.buyer_images.length - 3}</div>
                              </div>
                            ) : (
                              <img
                                src={media.name}
                                alt="img"
                                title="photo"
                                className="img-cover"
                              />
                            )}
                          </Col>
                        ))
                      ) : (
                        ''
                      )}
                      {Array.isArray(data.buyer_images) && data.buyer_images.some(media => media.name.includes("mp4")) && (
                        <Col xs={3} onClick={handleOpenModal}>
                          {data.buyer_images
                            .filter(media => media.name.includes("mp4"))
                            .map((media, index) => (
                              <video key={index} controls className="video-cover">
                                <source src={media.name} type="video/mp4" />
                              </video>
                            ))}
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                  <ShowMediaModal title='Hình ảnh từ người mua' show={showModal} handleClose={handleCloseModal} mediaList={data.buyer_images || []} />
                </Card>
              :
              ''
            }
            {
              type === 'purchase' ?
                (data.status === 'rejected' && data.complaint_status === 'none' ) ?
                  <div className="mt-2 d-flex">
                    <button className="btn btn-warning" onClick={() => handleUpdateComplaintStatusRefundInvoice('pending')}>Khiếu nại đơn hàng</button>
                  </div>
                :
                (data.status === 'requested' && data.complaint_status === 'none') ?
                  <div className="mt-2 d-flex">
                    <button className="btn btn-warning" onClick={() => handleUpdateStatusRefundInvoice('cancelled')}>Huỷ trả hàng</button>
                  </div>
                :
                  (data.status === 'rejected' && data.complaint_status === 'pending') ?
                    <div className="mt-2 d-flex">
                      <button className="btn btn-warning" onClick={() => handleUpdateComplaintStatusRefundInvoice('cancelled')}>Huỷ khiếu nại</button>
                    </div>
                  :
                    ''
              :
              data.status === 'requested' && data.complaint_status === 'none' ?
                <>
                  <div className="mt-2 d-flex">
                    <button className="btn btn-warning mr-2" onClick={() => handleUpdateStatusRefundInvoice('rejected')}>Từ chối</button>
                    <button className="btn btn-success" onClick={() => handleUpdateStatusRefundInvoice('approved')}>Chấp nhận</button>
                  </div>
                  <RefundModal
                    modalTitle = "Lý do từ chối trả hàng"
                    invoiceUserId = {data.id}
                    type={"seller"}
                    openModal={returnModal}
                    setOpenModal={setReturnModal}
                  />
                </>
              :
              ''
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailRefundInvoice;
