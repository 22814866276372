import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import MedalImg from '../../../assets/images/medal.png';
import NoImage from '../../../assets/images/photos/no_image.png';
import NextArrow from '../../../assets/images/next-arrow.png';
import PrevArrow from '../../../assets/images/prev-arrow.png';

const ListEnterprise = data => {
  let sliderRef = useRef(null);

  function NextSlide(props) {
    const { onClick } = props;
    return (
      <div className="position-absolute z-1 pointer slider-next-right">
        <div onClick={onClick} className="slider-next-arrow">
          <img src={NextArrow} alt="next list" />
        </div>
      </div>
    );
  }

  function PrevSlide(props) {
    const { onClick } = props;
    return (
      <div className="position-absolute z-1 pointer slider-prev-left">
        <div onClick={onClick} className="slider-prev-arrow">
          <img src={PrevArrow} alt="next list" />
        </div>
      </div>
    );
  }

  const slickNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const slickPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 2,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
    nextArrow: <NextSlide onClick={slickNext} />,
    prevArrow: <PrevSlide onClick={slickPrev} />,
    responsive: [
      {
        breakpoint: 628,
        settings: {
          swipeToSlide: true,
        },
      },
    ],
  };

  function EnterpriseItem() {
    return (
      <>
        <Slider {...settings} ref={sliderRef}>
          {data?.map(function (enterprise) {
            return (
              <div key={enterprise.id}>
                <div className="item-lst hover mr-2 mt-3">
                  <Link to={`/doanh-nghiep/${enterprise?.slug}`} state={{ enterprise }}>
                    <div
                      className="bg-img-orgnal position-relative"
                      style={{
                        background: `url(${enterprise?.image || NoImage}`,
                      }}
                    />
                    {!!enterprise.prestige && (
                      <div className="position-absolute enterprise-pretige">
                        <img src={MedalImg} alt="" />
                      </div>
                    )}
                  </Link>
                </div>
              </div>
            );
          })}
        </Slider>
      </>
    );
  }

  return (
    <>
      <div className="mt-1 position-relative">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="title-line-lt text-uppercase fw-bold my-3">Doanh nghiệp sản xuất</h5>
          <div className="text-secondary">
            <Link to="/danh-sach-doanh-nghiep">Xem tất cả</Link>
          </div>
        </div>

        <div className="row prod-list mx-2 w-100">{data && <EnterpriseItem />}</div>
      </div>
    </>
  );
};

export default ListEnterprise;
