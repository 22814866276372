import React from 'react';
import parse from 'html-react-parser';
import MedalImg from '../../../assets/images/medal.png';

const EnterpriseInfo = ({ enterprise, seeMore, onSeeMore }) => {
  return (
    <div className="my-2">
      <div className="row">
        <div className="col-4">
          <div className="position-relative">
            <img src={enterprise.image} alt="anh doanh nghiep" />
          </div>
          {!!enterprise.prestige && (
            <div className="position-absolute enterprise-pretige">
              <img src={MedalImg} alt="" />
            </div>
          )}
        </div>
        <div className="col-8">
          <div className="pl-2">
            <div className="address">
              <span className="fw-bold">Địa chỉ: </span>
              <span className="mr-1">{enterprise.address ?? ''}</span>
              <span className="mr-1">{enterprise.district_name ?? ''}</span>
              <span className="mr-1">{enterprise.province_name ?? ''}</span>
            </div>
            <div className="d-flex align-items-center">
              <div className="email">
                <span className="fw-bold">Email: </span>
                <a href={`mailto:${enterprise.email ?? ''}`} target="_blank">
                  <span className="mr-1">{enterprise.email ?? ''}</span>
                </a>
              </div>
              <div
                className={`more text-decoration-underline text-success ${!seeMore ? 'd-block' : 'd-none'} `}
                onClick={onSeeMore}
              >
                Xem thêm
              </div>
            </div>
            <div className={`${seeMore ? 'd-block' : 'd-none'}`}>
              <div className="phone">
                <span className="fw-bold">Điện thoại: </span>
                <a href={`tel:${enterprise.phone ?? ''}`} target="_blank">
                  <span className="mr-1">{enterprise.phone ?? ''}</span>
                </a>
              </div>
              <div className="website">
                <span className="fw-bold">Website: </span>
                <a href={enterprise.website ?? ''} target="_blank">
                  <span className="mr-1">{enterprise.website ?? ''}</span>
                </a>
              </div>
              <div className="descript d-flex">
                <span className="fw-bold">Mô tả: </span>
                <span className="mr-1">{parse(enterprise.content ?? '')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EnterpriseInfo);
